import React, { useState, useEffect } from 'react'
import StarRating from './star-rating'
import Carousel from './carousel'
import scrollTo from 'gatsby-plugin-smoothscroll'
import styles from './reviews.module.css'

/**
 * Fetch and display reviews from stamped.io
 */
const Reviews = ({
  productId = '',
  title = '',
  preTitle = '',
  lead = '',
  featuredOnly,
  compact = false,
  showTotals = false,
  showStars = true,
  flexLayout = false,
  ...props
}) => {
  const [reviewsData, setReviewsData] = useState(undefined)
  const [reviewsTotal, setReviewsTotal] = useState(undefined)
  const storeUrl = 'www.hanshelse.no'
  const apiKey = 'pubkey-MW2i190qpW68X76UQ1zq52cjZ984G6'

  useEffect(() => {
    window.fetch(`https://stamped.io/api/widget/reviews?productId=${productId}&productType&email&isWithPhotos&minRating&take&dateFrom&dateTo&sortReviews=featured&storeUrl=${storeUrl}&apiKey=${apiKey}`)
      .then(response => response.status !== 200 ? null : response)
      .then(response => response.json())
      .then(resultData => {
        if (featuredOnly && resultData.data) {
          resultData.data = resultData.data.filter(review => review.featured)
        }

        setReviewsData(resultData)
      })
      .catch(error => {
        // do nothing.
      })

    window.fetch(`https://stamped.io/api/widget/reviews?productId=${productId}&productType&email&isWithPhotos&minRating&take&dateFrom&dateTo&sortReviews=featured&storeUrl=${storeUrl}&apiKey=${apiKey}&type=site-badge`)
      .then(response => response.status !== 200 ? null : response)
      .then(response => response.json())
      .then(resultData => {
        setReviewsTotal(resultData)

        const template = `
          <div class="text-xs md:text-sm">
            <div class="inline-flex mr-2">
              <div class="text-signal opacity-100">★</div>
              <div class="text-signal opacity-100">★</div>
              <div class="text-signal opacity-100">★</div>
              <div class="text-signal opacity-100">★</div>
              <div class="text-signal opacity-100">★</div>
            </div>
            <button class="js-scroll-to-reviews underline">${resultData.total} har allerede anbefalt oss</a>
          </div>
        `

        const shortcuts = document.querySelectorAll('.js-reviews-shortcut')

        for (let i = 0; i < shortcuts.length; i++) {
          shortcuts[i].innerHTML = template
        }

        const buttons = document.querySelectorAll('.js-scroll-to-reviews')

        for (let i = 0; i < buttons.length; i++) {
          buttons[i].addEventListener('click', (e) => {
            scrollTo('#anmeldelser')
          })
        }
      })
      .catch(error => {
        // do nothing.
      })
  }, [])

  return (
    <>
      {reviewsData && reviewsData.data.length && (
        <div {...props} id="anmeldelser">
          <div className={`${flexLayout ? styles.flexLayout : ''}`}>
            <div className={`${styles.header} px-4 lg:px-12 xl:px-24 text-center lg:text-left mb-12 md:mb-16 max-w-screen-md`}>
              {preTitle && !showTotals && (
                <div className='text-xs mb-2'>
                  {preTitle}
                </div>
              )}

              {showTotals && reviewsTotal && (
                <div className='text-xs mb-2'>
                  {showStars && (
                    <StarRating score={reviewsTotal.rating} />
                  )}
                  {reviewsTotal.rating}/5 basert på {reviewsTotal.total} anmeldelser
                </div>
              )}

              {title && (
                <h2 className='display-h2'>
                  {title}
                </h2>
              )}

              {lead && (
                <div className='lead'>
                  {lead}
                </div>
              )}
            </div>

            <Carousel
              className={`${styles.carousel} relative pl-4 lg:pl-12 xl:pl-24`}
              buttons={false}
              progressbar={false}
            >
              {reviewsData.data.map((review, i) => (
                <div className='w-72 flex-shrink-0 pr-5' key={i}>
                  <div className='h-full bg-white p-5 flex flex-col'>
                    <div className='text-xs sm-text-sm'>
                      <FormattedDate date={review.reviewDate} />
                    </div>
                    <div className='mb-4'>
                      <StarRating score={review.reviewRating} className='text-primary' />
                    </div>
                    <div className='font-medium text-sm sm:text-base mb-2'>
                      {review.reviewTitle}
                    </div>
                    <p className='text-xs sm:text-sm'>
                      <TruncatedText text={review.reviewMessage} />
                    </p>
                    <div className='font-bold text-xs sm:text-sm uppercase mt-auto'>
                      {review.author}
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      )}
    </>
  )
}

const TruncatedText = ({ text }) => {
  text = text.trim()

  const threshold = 133.7
  const length = text.length
  const [isTruncated, setTruncated] = useState(length > threshold)

  return (
    <>
      {isTruncated ? (
        <>
          {text.substring(0, threshold)}&nbsp;
          <button className='underline' onClick={(e) => {
            e.preventDefault()
            setTruncated(false)
          }}>les mer...</button>
        </>
      ) : text}
    </>
  )
}

const FormattedDate = ({ date }) => {
  const parts = date.split(' ')
  const formattedDate = `${parts[0]}. ${parts[1]} ${parts[2]}`

  return (
    formattedDate
  )
}

export default Reviews
