import React, { useEffect, useState, useCallback } from 'react'
import { setupWheelGestures } from 'embla-carousel-wheel-gestures'
import { useEmblaCarousel } from 'embla-carousel/react'

const DotButton = ({ selected, onClick }) => (
  <button
    className={`h-2 w-2 mr-4 bg-primary lg:hidden rounded-full ${!selected ? 'opacity-25' : ''}`}
    type="button"
    onClick={onClick}
  />
)

const Carousel = ({ children, buttons = true, progressbar = false, ...props }) => {
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    loop: false,
    align: 'start'
  })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [scrollProgress, setScrollProgress] = useState(0)

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [
    embla
  ])

  const onScroll = useCallback(() => {
    if (!embla) return
    let progress = Math.max(0, Math.min(1, embla.scrollProgress()))
    progress = progress * 100 + 3
    setScrollProgress(Math.min(100, progress))
  }, [embla, setScrollProgress])

  useEffect(() => embla && setupWheelGestures(embla), [embla])

  useEffect(() => {
    if (!embla) return

    onSelect()

    if (progressbar) {
      onScroll()
      embla.on('scroll', onScroll)
    }

    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect, onScroll])

  return (
    <EmblaCarouselReact {...props}>
      <div className={`embla__container ${props.useGrid ? 'grid' : 'flex'}`}>
        {children}
      </div>

      {buttons && (
        <div className='flex justify-center lg:justify-start'>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
      )}

      {progressbar && (
        <div className='pr-4 lg:pr-12 xl:pr-20 mt-8 md:mt-16'>
          <div className='rounded-md bg-primary-lighter relative overflow-hidden' style={{height: '6px'}}>
            <div
              className='absolute top-0 right-full w-full h-full bg-primary'
              style={{ transform: `translateX(${scrollProgress}%)` }}
            />
          </div>
        </div>
      )}
    </EmblaCarouselReact>
  )
}

export default Carousel
